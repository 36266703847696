/* eslint-disable */

import moment from 'moment'
import { getLanguageCode } from '@/locales/i18n'

export class GenericModel {
  static get fields() {
    throw new Error('Not implemented')
  }

  static presenterField(row, fieldName) {
    if (!this.fields[fieldName]) {
      throw new Error(`${fieldName} not found`)
    }

    return this.fields[fieldName].forPresenter(
      row[this.fields[fieldName].name]
    )
  }

  static presenter(row, fieldName) {
    const val = row != null ? row[fieldName] : null
    return val || 'ـــ'
  }

  static presenterInteger(row, fieldName) {
    const val = row != null ? row[fieldName] : null
    return val || 0
  }

  static presenterDecimal(row, fieldName, fractionDigits = 2) {
    const val = row != null ? row[fieldName] : null
    return val ? (Math.round(val * 100) / 100).toFixed(fractionDigits) : 0
  }

  static presenterLocalization(row, fieldName) {
    // const val = PlanModel.presenter(row, fieldName);
    const val = row != null ? row[fieldName] : null
    const valueEn = val && typeof val === 'object' ? val.en : null
    const valueAr = val && typeof val === 'object' ? val.ar : null
    const value = getLanguageCode() === 'ar'
      ? valueAr != null ? valueAr : valueEn
      : valueEn != null ? valueEn : valueAr
    return value != null ? value : 'ـــ'
  }

//#region [ DATE & TIME ]
  static formatDate(date) {
    return moment.unix(date.seconds).format('DD/MM/YYYY')
  }

  static presenterDateTime(row, fieldName) {
    const val = row != null ? row[fieldName] : null
    return val ? moment(val).locale(getLanguageCode()).format('DD-MM-YYYY h:mm A') : 'ـــ'
  }

  static presenterDate(row, fieldName) {
    const val = row != null ? row[fieldName] : null
    return val ? moment(val).locale(getLanguageCode()).format('D MMM, YYYY') : 'ـــ'
  }

  static presenterDay(row, fieldName) {
    const val = row != null ? row[fieldName] : null
    return val ? moment(val).locale(getLanguageCode()).format('dddd') : 'ـــ'
  }

  static presenterTime(row, fieldName) {
    const val = row != null ? row[fieldName] : null
    return val ? moment(val, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(getLanguageCode()).format('hh:mm a') : 'ـــ'
  }
//#endregion
}
